import React from 'react';
import { graphql } from 'gatsby';

import { HackathonSubmissionsPage } from 'components/Hackathon';

export default function PageHack0Gallery(props) {
  return <HackathonSubmissionsPage {...props} />;
}

export const query = graphql`
  query HackathonGalleryPageQuery {
    page: strapiHack0GalleryPage {
      seo_title
      seo_description
      seo_keywords
      title
      description
    }
    allStrapiHack0GalleryItem {
      submissions: nodes {
        id
        title
        team_name
        video_url
        github_url
        order
        description {
          data {
            description
          }
        }
        short_description
        preview_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1080)
            }
          }
        }
      }
    }
  }
`;
